import React, { useEffect, useState } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { baseUrl } from '../helper/helper';
import { pushOnPortal } from '../jsonData/pushonportal';
// import { event } from 'jquery';

const PushOnPortal = () => {
    
    const [activeTab, setActiveTab] = useState('tab1');
    const [pushToShopify, setPushToShopify] = useState(pushOnPortal.supplierproductlist)
    const changeTab=(tab, event)=>{
        event.preventDefault();
        setActiveTab(tab); 
    }
    useEffect(()=>{
        const $ = window.$; // Access jQuery from the global window object
            if ($ && $.fn.niceSelect) {
            $('.nice_Select').niceSelect();

            return ()=>{
                $('.nice_Select').niceSelect('destroy');
            }
 

            } else {
            console.error('jQuery or niceSelect is not available');
            }
    },[])

    
  return (
    <>
       <section className="main_content dashboard_part large_header_bg">
       <Header />
            <div className="main_content_iner overly_inner">
                <div className="container-fluid p-0 "> 
                    <div className="row">
                        <div className="col-md-12">
                                <div className="pushportalTabbing">
                                    <ul className="nav" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`} id="PushtoPortal-tab" data-toggle="tab" href="#PushtoPortal" onClick={(e) => changeTab('tab1',e)}>Push to Shopping Portal</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`}   href="#pushedtoPortal"  onClick={(e) => changeTab('tab2',e)}
                                                >Pushed on Portal</a>
                                        </li>
                                    </ul>
                                </div> 
                        
                        {/* ====================================================================================== */}

                        <div className="pushportaltabContent">
                            <div className="tab-content" id="myTabContent">
                            {/* ==========================first tab start================================================ */}
                            <div className={`tab-pane fade ${activeTab === 'tab1' ? 'active show' : ''}`} id="PushtoPortal" role="tabpanel" aria-labelledby="PushtoPortal-tab">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="white_card mb_20 ">
                                                <div className="white_card_header">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <select className="nice_Select wide" id="storeSelect">
                                                                    <option className="text-sm font-semibold">Select-One</option>
                                                                    <option className="text-sm font-semibold" value="3">Shopify-Patel solutions (https://ad473f.myshopify.com)</option>
                                                                    <option className="text-sm font-semibold" value="66">RouteWay-myMart (https://01mymart.ind.in)</option>
                                                                    <option className="text-sm font-semibold" value="10652">Shopify-routewayapptest (routewayapptest.myshopify.com)</option>
                                                                    <option className="text-sm font-semibold" value="20698">Shopify-ssdfsdf (dfsdfs)</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {pushToShopify.length > 0 && pushToShopify.map((item) => (
                                                
                                                <div className="col-lg-12" key={item.Product_ID}>
                                                        <div className="white_card mb_20 ">
                                                            <div className="white_card_body QA_section orderList pushportalPadding">
                                                                <div className="bulder_tab_wrapper pushPortalHeader">
                                                                    <h5>{item.Product_Name}</h5>
                                                                </div>
                                                                <div>
                                                                    <div className="" >
                                                                        <div className="QA_table tab-content mt-3" id="myTabContent">
                                                                            <div id="" className="dataTables_wrapper no-footer ">
                                                                                <table className="table pushontable dataTable lms_table_active p-0 collapsed">
                                                                                
                                                                                    <tbody>
                                                                                    {item.Variants.length > 0 && item.Variants.map((variant) => (
                                                                                               
                                                                                                 <tr role="row" className="odd">
                                                                                                    <td>{variant.Product_Variat_ID}</td>
                                                                                                    <td>{variant.P_SKU}</td>
                                                                                                    <td width="400">{variant.Variant_Name}</td>
                                                                                                    <td>₹{variant.Price}</td>
                                                                                                    <td width="100px">
                                                                                                        <input type="text" className="form-control" /></td>
                                                                                                        {/* <td><button type="button" className="pushonBtn btn btn-primary rounded-pill">Save <i className="fa-solid fa-plus"></i></button></td>  */}
                                                                                                </tr> 
                                                                                            ))}
                                                                                       
                                                                                      
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={{display:'flex', justifyContent:'flex-end', marginRight:'12px' }}> <span><button type="button" className="pushonBtn btn btn-primary rounded-pill" style={{padding:'5px 30px', lineHeight:'22px'}}>Save <i className="fa-solid fa-plus"></i></button></span>  </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                            ))}

                                        {/* <div className="col-lg-12">
                                            <div className="white_card mb_20 ">
                                                <div className="white_card_body QA_section orderList pushportalPadding">
                                                    <div className="bulder_tab_wrapper pushPortalHeader">
                                                        <h5>Baby Nail Trimmer</h5>
                                                    </div>
                                                    <div>
                                                        <div className="" >
                                                            <div className="QA_table tab-content mt-3" id="myTabContent">
                                                                <div id="" className="dataTables_wrapper no-footer ">
                                                                    <table className="table pushontable dataTable lms_table_active p-0 collapsed "> 
                                                                        <tbody> 
                                                                            <tr role="row" className="odd">
                                                                                <td>0</td>
                                                                                <td>RW</td>
                                                                                <td width="400">Baby Nail Trimmer</td>
                                                                                <td>350.00</td>
                                                                                <td width="100px">
                                                                                    <input type="text" className="form-control" /></td>
                                                                                <td><button type="button" className="pushonBtn btn btn-primary rounded-pill">Save <i className="fa-solid fa-plus"></i></button></td>
                                                                            </tr> 
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
            
            
                                        <div className="col-lg-12">
                                            <div className="white_card mb_20 ">
                                                <div className="white_card_body QA_section orderList pushportalPadding">
                                                    <div className="bulder_tab_wrapper pushPortalHeader">
                                                        <h5>Silicone Body Back Scrubber</h5>
                                                    </div>
                                                    <div>
                                                        <div className="" >
                                                            <div className="QA_table tab-content mt-3" id="myTabContent">
                                                                <div id="" className="dataTables_wrapper no-footer ">
                                                                    <table className="table pushontable dataTable lms_table_active p-0 collapsed ">
                                                                       
                                                                        <tbody>
                                                                            <tr role="row" className="odd">
                                                                                <td>0</td>
                                                                                <td>RW</td>
                                                                                <td width="400">Silicone Body Back Scrubber</td>
                                                                                <td>220.00</td>
                                                                                <td width="100px">
                                                                                    <input type="text" className="form-control" /></td>
                                                                                <td><button type="button" className="pushonBtn btn btn-primary rounded-pill">Save <i className="fa-solid fa-plus"></i></button></td>
                                                                            </tr>
                    
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  */}
                                    </div>
                                </div>
                                {/* ===========================================first tab end================================================================ */}
                                {/* ===========================================second tab start================================================================ */}
                                <div
                                        className={`tab-pane fade ${activeTab === 'tab2' ? 'active show' : ''}`}
                                        id="pushedtoPortal"
                                        role="tabpanel"
                                        aria-labelledby="pushedtoPortal-tab"
                                        >
                                        <div className="QA_table tab-content mt-3" id="myTabContent">
                                            <div id="" className="dataTables_wrapper no-footer ">
                                            <table className="table pushedTable dataTable lms_table_active p-0 collapsed ">
                                                <thead>
                                                <tr role="row">
                                                    <th>S.no</th>
                                                    <th
                                                    scope="col"
                                                    className="sorting_asc"
                                                    tabIndex={0}
                                                    aria-controls="NewOrder"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    style={{ width: 200 }}
                                                    aria-sort="ascending"
                                                    aria-label="Product Name: activate to sort column descending"
                                                    >
                                                    P name
                                                    </th>
                                                    <th
                                                    scope="col"
                                                    className="sorting"
                                                    tabIndex={0}
                                                    aria-controls="NewOrder"
                                                    rowSpan={1}
                                                    aria-label="Total: activate to sort column ascending"
                                                    >
                                                    Image
                                                    </th>
                                                    <th
                                                    scope="col"
                                                    className="sorting"
                                                    tabIndex={0}
                                                    aria-controls="NewOrder"
                                                    rowSpan={1}
                                                    aria-label="Selling Price: activate to sort column ascending"
                                                    >
                                                    Variant/Product
                                                    </th>
                                                    <th
                                                    scope="col"
                                                    className="sorting"
                                                    tabIndex={0}
                                                    aria-controls="NewOrder"
                                                    rowSpan={1}
                                                    aria-label="Cancel By DS: activate to sort column ascending"
                                                    >
                                                    Portal
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr role="row" className="odd">
                                                    <td>1</td>
                                                    <td> Self Defence Stick</td>
                                                    <td>
                                                    <img src={`${baseUrl}front/img/401_202300080656.jpeg`} alt="img" width="50px" />
                                                    </td>
                                                    <td>
                                                    <table
                                                        style={{
                                                        borderWidth: 1,
                                                        borderColor: "rgb(163, 163, 162)",
                                                        borderStyle: "solid",
                                                        borderRadius: 10
                                                        }}
                                                    >
                                                        <tbody>
                                                        <tr>
                                                            <th
                                                            style={{
                                                                borderWidth: "1px 1px 0px 0px",
                                                                borderStyle: "solid",
                                                                borderColor: "rgb(163, 163, 162)",
                                                                padding: 5,
                                                                fontWeight: 800
                                                            }}
                                                            >
                                                            SKU
                                                            </th>
                                                            <th
                                                            style={{
                                                                borderWidth: "1px 1px 0px 0px",
                                                                borderStyle: "solid",
                                                                borderColor: "rgb(163, 163, 162)",
                                                                padding: 5,
                                                                fontWeight: 800
                                                            }}
                                                            >
                                                            Item
                                                            </th>
                                                            <th
                                                            style={{
                                                                borderWidth: "1px 1px 0px 0px",
                                                                borderStyle: "solid",
                                                                borderColor: "rgb(163, 163, 162)",
                                                                padding: 5,
                                                                fontWeight: 800
                                                            }}
                                                            >
                                                            Price
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                            style={{
                                                                borderWidth: "1px 1px 0px 0px",
                                                                borderStyle: "solid",
                                                                borderColor: "rgb(163, 163, 162)",
                                                                padding: 5
                                                            }}
                                                            >
                                                            RW338
                                                            </td>
                                                            <td
                                                            style={{
                                                                borderWidth: "1px 1px 0px 0px",
                                                                borderStyle: "solid",
                                                                borderColor: "rgb(163, 163, 162)",
                                                                padding: 5
                                                            }}
                                                            >
                                                            SELF DEFENCE STICK
                                                            </td>
                                                            <td
                                                            style={{
                                                                borderWidth: "1px 1px 0px 0px",
                                                                borderStyle: "solid",
                                                                borderColor: "rgb(163, 163, 162)",
                                                                padding: 5
                                                            }}
                                                            >
                                                            889.00
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    </td>
                                                    <td>RouteWay (https://01mymart.ind.in)</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td tabIndex={0} className="sorting_1">
                                                    Self Defence Stick
                                                    </td>
                                                    <td>
                                                    <img src={`${baseUrl}front/img/401_202300080656.jpeg`} alt="img" width="50px" />
                                                    </td>
                                                    <td>
                                                    <table
                                                        style={{
                                                        borderWidth: 1,
                                                        borderColor: "rgb(163, 163, 162)",
                                                        borderStyle: "solid",
                                                        borderRadius: 10
                                                        }}
                                                    >
                                                        <tbody>
                                                        <tr>
                                                            <th
                                                            style={{
                                                                borderWidth: "1px 1px 0px 0px",
                                                                borderStyle: "solid",
                                                                borderColor: "rgb(163, 163, 162)",
                                                                padding: 5,
                                                                fontWeight: 800
                                                            }}
                                                            >
                                                            SKU
                                                            </th>
                                                            <th
                                                            style={{
                                                                borderWidth: "1px 1px 0px 0px",
                                                                borderStyle: "solid",
                                                                borderColor: "rgb(163, 163, 162)",
                                                                padding: 5,
                                                                fontWeight: 800
                                                            }}
                                                            >
                                                            Item
                                                            </th>
                                                            <th
                                                            style={{
                                                                borderWidth: "1px 1px 0px 0px",
                                                                borderStyle: "solid",
                                                                borderColor: "rgb(163, 163, 162)",
                                                                padding: 5,
                                                                fontWeight: 800
                                                            }}
                                                            >
                                                            Price
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                            style={{
                                                                borderWidth: "1px 1px 0px 0px",
                                                                borderStyle: "solid",
                                                                borderColor: "rgb(163, 163, 162)",
                                                                padding: 5
                                                            }}
                                                            >
                                                            RW338
                                                            </td>
                                                            <td
                                                            style={{
                                                                borderWidth: "1px 1px 0px 0px",
                                                                borderStyle: "solid",
                                                                borderColor: "rgb(163, 163, 162)",
                                                                padding: 5
                                                            }}
                                                            >
                                                            SELF DEFENCE STICK
                                                            </td>
                                                            <td
                                                            style={{
                                                                borderWidth: "1px 1px 0px 0px",
                                                                borderStyle: "solid",
                                                                borderColor: "rgb(163, 163, 162)",
                                                                padding: 5
                                                            }}
                                                            >
                                                            889.00
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    </td>
                                                    <td className="color_text_6">RouteWay (https://01mymart.ind.in)</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                        </div>
                                {/* ===========================================second tab end================================================================ */}



                            </div>
                         </div>
                         </div>
                    </div>
                </div>
            </div>
        <Footer />
       </section>

    </>
  )
}

export default PushOnPortal
