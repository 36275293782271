import React, { useEffect, useState } from 'react';
import HomeProduct from '../components/homeProduct';
import HomeCategory from '../components/homeCategory';
import OwlCarousel from 'react-owl-carousel'; 
import $ from 'jquery';

import { productsData ,categoryData} from '../Products/product';
import Header from '../components/header';
import Footer from '../components/footer';
import HiddenCompnents from '../components/hiddenCompnents';
import { Link } from 'react-router-dom';

import {landingProducts} from '../jsonData/LandigProduct'
import { productDeatil } from '../jsonData/productdetails';
 
 


export default function HomePage() {
   
  
  const [homeCategories, setHomeCategories] = useState(landingProducts.ProductCategory);
   
  
  const [pushData , setPushData] = useState({
          SKU: '',
          Product_ID: 21,
          CATEGORYNAME: '',
          Product_Name: '',
          CurrentStock: 0,
          Our_Cost: 0,
          docname: 0,
          Selected: 0,
          sell_price:0,
          margin :0,
          Varient :[],
          Portal : [],
          pushProductOn:'',
      });
  
      function pushProduct(productData){ 

        const productDetails = productDeatil;
        $('.pushtoSidebar').animate({ right: '0px' }, 300);
        setPushData(
          {
              SKU: productData.SKU,
              Product_ID: productData.Product_ID,
              CATEGORYNAME: productData.CATEGORYNAME,
              Product_Name: productData.Product_Name,
              CurrentStock: productData.CurrentStock,
              Our_Cost: productData.Our_Cost,
              docname: productDetails.Image[0].FileName,
              Selected: productData.Selected ,
              sell_price: productData.Our_Cost,
              margin :0,
              Varient: productDetails.Varient,
              Portal: productDetails.Portal,
              pushProductOn :''
          }
        )
        
      }
    

    const options = {
        loop: true,
        margin: 10,
        nav: true,
        navText: [
            '<i class="fa-solid fa-arrow-left"></i>',
            '<i class="fa-solid fa-arrow-right"></i>'
        ],
        dots: false,
        responsive: {
            0: {
                items: 3
            },
            600: {
                items: 5
            },
            1000: {
                items: 8
            }
        }
    };

    const products = {
        loop:false,
        margin:10,
        nav:true,
        navText : ['<i class="fa-solid fa-arrow-left"></i>','<i class="fa-solid fa-arrow-right"></i>'],
        dots:false,
        responsive:{
            0:{
                items:3
            },
            600:{
                items:5
            },
            1000:{
                items:6
            }
        }
    }

    const setVariantPushDataFun =(e)=>{
      const { name, value } = e.target;
      setPushData(pushData => (
      {
        
        ...pushData,
        Varient: pushData.Varient.map(variant => 
          variant.Variant_Name === name 
            ? {
                ...variant, 
                sprice: value, 
                margin: value - variant.price  
              }
            : variant
        )
      }));

      console.log(pushData);
    }

    const setPushDataFun=(e)=>{
      const { name, value } = e.target; 
      setPushData({ ...pushData,  [name]: value }); 
    }
    
    useEffect(() => {
        
         // Open sidebar on click of 'infoSideBarOpener'
        //  $('.pushtoBtn').click(function() {
        //     $('.pushtoSidebar').animate({ right: '0px' }, 300);
        // });
    
        // Close sidebar on click of 'infoSideBarCloser'
        $('.pushtoCloser').click(function() {
            $('.pushtoSidebar').animate({ right: '-100%' }, 300);
        }); 

       
        
      }, []);
 

      

  return (
    <>
     
          
        <section className="main_content dashboard_part large_header_bg">
          <Header />
          <div className="main_content_iner overly_inner ">
            <div className="container-fluid p-0 ">
              <div className="row">
                <div className="col-xl-12">
                  <div className="homeBanner mb_30">
                    <div className="homeBnrImg">
                      <a href="test"> <img src="front/img//banners/banner1.jpg" alt="bnrimg" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <OwlCarousel   className="categorySlider owl-carousel owl-theme mb_30" {...options}>
                  {homeCategories.map((product)=>(  
                        <HomeCategory category={product}  key={product.id}/>
                    ))}
                    
                    
                  </OwlCarousel>
                </div>
              </div>
             
              {homeCategories.map((category,index) => {
                 
                 const productsData = landingProducts.Product.filter(product => product.CATEGORYNAME === category.CATEGORYNAME);
                  
                 if(productsData.length>0){

                 
                      return (
                        <div className="row" key={category.id}>
                          <div className="col-md-12">
                            <div className="homeprList mb_30">
                              <div className="homeprHeader">
                                <h5>{category.CATEGORYNAME}</h5>
                                <Link to="/category">
                                  View: <span>{productsData.length}</span>
                                </Link>
                              </div>
                              <OwlCarousel className="homePrSlider owl-carousel owl-theme" {...products}>
                                {productsData.map((product,index2) => (
                                  <HomeProduct pushProduct={() => pushProduct(product)} product={product}  key={product.id} />
                                ))}
                              </OwlCarousel>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        })}
              {/* <div className="row">
                <div className="col-md-12">
                  <div className="homeprList mb_30">
                    <div className="homeprHeader">
                      <h5>New Arrivals</h5>
                      <Link to="/category">View : <span>{productsData.length}</span></Link>
                    </div>
                    <OwlCarousel className="homePrSlider owl-carousel owl-theme" {...products}>
                    
                    {productsData.map((product)=>(  
                        <HomeProduct pushProduct={pushProduct} product = {product} key={product.id}/>
                    ))}
                        
                    </OwlCarousel>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="homeprList mb_30">
                    <div className="homeprHeader">
                      <h5>Gadgets</h5>
                      <Link to="/category">View : <span>{productsData.length}</span></Link>
                    </div>
                    <OwlCarousel className="homePrSlider owl-carousel owl-theme" {...products}>
                   
                    {productsData.map((product)=>(  
                        <HomeProduct pushProduct={pushProduct} product = {product} key={product.id}/>
                    ))}

                    </OwlCarousel>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="homeprList mb_30">
                    <div className="homeprHeader">
                      <h5>Kids</h5>
                      <Link to="/category">View : <span>{productsData.length}</span></Link>
                    </div>
                    <OwlCarousel className="homePrSlider owl-carousel owl-theme" {...products}>
                  
                    {productsData.map((product)=>(  
                        <HomeProduct pushProduct={pushProduct} product = {product} key={product.id}/>
                    ))}

                    </OwlCarousel>
                  </div>
                </div>
              </div>
                
                
               
             
              <div className="row">
                <div className="col-md-12">
                  <div className="homeprList mb_30">
                    <div className="homeprHeader">
                      <h5>Home &amp; Kitchen</h5>
                      <Link to="/category">View : <span>{productsData.length}</span></Link>
                    </div>
                    <OwlCarousel className="homePrSlider owl-carousel owl-theme" {...products}>
                       
                    {productsData.map((product)=>(  
                        <HomeProduct pushProduct={pushProduct} product = {product} key={product.id}/>
                    ))}

                    </OwlCarousel>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="homeprList mb_30">
                    <div className="homeprHeader">
                      <h5>Bueaty &amp; Health</h5>
                      <Link to="/category">View : <span>{productsData.length}</span></Link>
                    </div>
                    <OwlCarousel className="homePrSlider owl-carousel owl-theme" {...products}>
                    {productsData.map((product)=>(  
                        <HomeProduct pushProduct={pushProduct} product = {product} key={product.id}/>
                    ))}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="homeprList mb_30">
                    <div className="homeprHeader">
                      <h5>Trending Products</h5>
                      <Link to="/category">View : <span>{productsData.length}</span></Link>
                    </div>
                    <OwlCarousel className="homePrSlider owl-carousel owl-theme" {...products}>
                    {productsData.map((product)=>(  
                        <HomeProduct pushProduct={pushProduct} product = {product} key={product.id}/>
                    ))}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="homeprList mb_30">
                    <div className="homeprHeader">
                      <h5>Car &amp; Bike Accessories</h5>
                      <Link to="/category">View : <span>{productsData.length}</span></Link>
                    </div>
                    <OwlCarousel className="homePrSlider owl-carousel owl-theme" {...products}>
                    {productsData.map((product)=>(  
                        <HomeProduct pushProduct={pushProduct} product = {product} key={product.id}/>
                    ))}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="homeprList mb_30">
                    <div className="homeprHeader">
                      <h5>Hot Selling</h5>
                      <a href="test">View : <span>{productsData.length}</span></a>
                    </div>
                    <OwlCarousel className="homePrSlider owl-carousel owl-theme" {...products}>
                    {productsData.map((product)=>(  
                        <HomeProduct pushProduct={pushProduct} product = {product} key={product.id}/>
                    ))}
                    </OwlCarousel>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
         <Footer />
        </section>
      <HiddenCompnents pushData={pushData} setPushDataFun={setPushDataFun} setVariantPushDataFun={setVariantPushDataFun} />
        
      
    </>
  )
}
